import { Link, graphql } from "gatsby"
import * as React from "react"

import Layout from "@/components/Layout"
import { Bio, Seo } from "@/components/common"
import { INode, PageProps } from "@/definitions"
import ppImg from "../assets/pp.jpg"

const BlogIndex: React.FC<PageProps> = ({ data, location }) => {
    const siteTitle = data.site.siteMetadata?.title || `Title`
    const posts = data.allMdx.edges

    if (posts.length === 0) {
        return (
            <Layout location={location} title={siteTitle}>
                <Seo title="All posts" />
                <Bio />
                <p>
                    No blog posts found. Add markdown posts to "content/blog" (or the
                    directory you specified for the "gatsby-source-filesystem" plugin in
                    gatsby-config.js).
                </p>
            </Layout>
        )
    }

    return (
        <Layout location={location} title={siteTitle}>
            <Seo title="All posts" />

            <div className="flex gap-4 justify-start mb-12 tracking-wide">
                <img className="w-10 h-10 rounded-full" src={ppImg} alt="" />
                <div className="font-medium dark:text-white">
                    <div>Personal blog by</div>
                    <div className="text-sm text-gray-500 dark:text-gray-400">Katili Jiwo Adi W</div>
                </div>
            </div>

            <ol className="divide-y max-w-2xl">

                {posts.map(({ node }: { node: INode }, index: number) => {
                    const title = node.frontmatter.title || node.fields.slug
                    const classes = index === 0 ? "pb-8" : "py-8"
                    return (
                        <li key={node.fields.slug} className={classes}>
                            <Link
                                to={node.fields.slug}
                                itemProp="url"
                            >
                                <article itemScope itemType="http://schema.org/Article" className="transform transition duration-300 font-medium rounded-lg py-3 px-2 hover:bg-gray-100 dark:hover:bg-gray-800">
                                    <header>
                                        <small className="font-yrsa text-primary text-lg">
                                            {node.frontmatter.date}
                                        </small>
                                        <h2 className="text-2xl font-exo font-black text-gray-700 dark:text-white mt-3">
                                            <span itemProp="headline">{title}</span>
                                        </h2>
                                    </header>
                                    <p
                                        dangerouslySetInnerHTML={{
                                            __html: node.excerpt || node.frontmatter.description,
                                        }}
                                        itemProp="description"
                                        className="text-lg font-yrsa text-skin-fg mt-3"
                                    />
                                    <section className="flex flex-wrap font-yrsa text-skin-fg-muted md:text-sm space-x-2 mt-3">
                                        {(node.frontmatter.tags || "")
                                            .split(",")
                                            .map((s: string) => s.trim())
                                            .map((s: string) => (
                                                <div
                                                    key={s}
                                                    className="relative grid select-none items-center whitespace-nowrap rounded-full py-1.5 px-3 font-sans text-xs font-bold bg-blue-900 text-blue-200 dark:text-blue-300">
                                                    {s}
                                                </div>
                                            ))}
                                    </section>
                                </article>

                            </Link>
                        </li>
                    )
                })}
            </ol>
        </Layout>
    )
}

export default BlogIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMdx(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            description
            tags
          }
        }
      }
    }
  }
`
